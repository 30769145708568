// appConfigSlice.js
import { createSlice } from '@reduxjs/toolkit';

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState: {
    isSidebarVisible: true,
    ledLive: false,
    shipSerials: [],
    neededSensors: [],
  },
  reducers: {
    setSidebarVisible: (state, action) => {
      state.isSidebarVisible = action.payload;
    },
    setLedLive: (state, action) => {
      state.ledLive = action.payload;
    },
    setShipSerials: (state, action) => {
      state.shipSerials = action.payload;
    },
    setNeededSensors: (state, action) => {
      state.neededSensors = action.payload;
    },
  }
});

export const { setSidebarVisible, setLedLive, setShipSerials,setNeededSensors } = appConfigSlice.actions;
export default appConfigSlice.reducer;
