import { useErrorBoundary } from "react-error-boundary";
import styles from './ErrorFallback.module.css';
import errorImage from './bug.svg';  // Import the SVG

function ErrorFallback({ error }) {
  const { resetBoundary } = useErrorBoundary();

  return (
    <div className={styles.container}>
      <img src={errorImage} alt="Error illustration" className={styles.errorImage} />  {/* Display SVG */}
      <p className={styles.message}>Something went wrong.</p>
      <button className={styles.retryButton} onClick={resetBoundary}>Try again</button>
    </div>
  );
}

export default ErrorFallback;
